/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import LogoSerios from '../assets/images/lp/logotipo_serios.svg'

import { 
  sucessContent,
  logoImg, 
}  from '../assets/styles/LP.styles'

const ContentSection = () => {
  return (
    <section css={sucessContent} style={{ background: '#23529a' }}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div css={logoImg}><img src={LogoSerios} alt="Colégio Seriös" title="Colégio Seriös" /></div>
            <div style={{ textAlign: 'center' }}>
              <iframe src="https://forms.monday.com/forms/embed/36016261175949d566d05a2dfa6ba6ea?r=use1" width="100%" height="3800" style={{ border: 'none' }} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}; 

export default ContentSection;
