import React from "react"
import Layout from "@/components/layout"
import PesquisaFamilyDayForm from "@/components/PesquisaFamilyDayForm"

const PesquisaFamilyDayPage = () => {
  return (
    <Layout>
      <PesquisaFamilyDayForm />
    </Layout>
  );
};

export default PesquisaFamilyDayPage;
